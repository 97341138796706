export const ADD_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAILED = "ADD_FAQ_FAILED";

export const EDIT_FAQ_REQUEST = "EDIT_FAQ_REQUEST";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_FAILED = "EDIT_FAQ_FAILED";

export const DELETE_FAQ_REQUEST = "DELETE_FAQ_REQUEST";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILED = "GET_FAQ_FAILED";

export const GET_ALL_FAQ_REQUEST = "GET_ALL_FAQ_REQUEST";
export const GET_ALL_FAQ_SUCCESS = "GET_ALL_FAQ_SUCCESS";
export const GET_ALL_FAQ_FAILED = "GET_ALL_FAQ_FAILED";

export const GET_ALL_FAQ_BY_LANGUAGE_REQUEST = "GET_ALL_FAQ_BY_LANGUAGE_REQUEST";
export const GET_ALL_FAQ_BY_LANGUAGE_SUCCESS = "GET_ALL_FAQ_BY_LANGUAGE_SUCCESS";
export const GET_ALL_FAQ_BY_LANGUAGE_FAILED = "GET_ALL_FAQ_BY_LANGUAGE_FAILED";

export const GET_ALL_LANGUAGES_WITH_FAQ_REQUEST = "GET_ALL_LANGUAGES_WITH_FAQ_REQUEST";
export const GET_ALL_LANGUAGES_WITH_FAQ_SUCCESS = "GET_ALL_LANGUAGES_WITH_FAQ_SUCCESS";
export const GET_ALL_LANGUAGES_WITH_FAQ_FAILED = "GET_ALL_LANGUAGES_WITH_FAQ_FAILED";
