export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";

export const SUSPEND_USER_REQUEST = "SUSPEND_USER_REQUEST";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILED = "SUSPEND_USER_FAILED";

export const UNSUSPEND_USER_REQUEST = "UNSUSPEND_USER_REQUEST";
export const UNSUSPEND_USER_SUCCESS = "UNSUSPEND_USER_SUCCESS";
export const UNSUSPEND_USER_FAILED = "UNSUSPEND_USER_FAILED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const GET_READING_PROGRESS_BY_USER_REQUEST = "GET_READING_PROGRESS_BY_USERS_REQUEST";
export const GET_READING_PROGRESS_BY_USER_SUCCESS = "GET_READING_PROGRESS_BY_USERS_SUCCESS";
export const GET_READING_PROGRESS_BY_USER_FAILED = "GET_READING_PROGRESS_BY_USERS_FAILED";

export const GET_USER_FAV_BOOKS_REQUEST = "GET_USER_FAV_BOOKSS_REQUEST";
export const GET_USER_FAV_BOOKS_SUCCESS = "GET_USER_FAV_BOOKSS_SUCCESS";
export const GET_USER_FAV_BOOKS_FAILED = "GET_USER_FAV_BOOKSS_FAILED";

export const GET_USER_FAV_IMAGES_REQUEST = "GET_USER_FAV_IMAGESS_REQUEST";
export const GET_USER_FAV_IMAGES_SUCCESS = "GET_USER_FAV_IMAGESS_SUCCESS";
export const GET_USER_FAV_IMAGES_FAILED = "GET_USER_FAV_IMAGESS_FAILED";
