export const CHAPTER_UPLOAD_REQUEST = "CHAPTER_UPLOAD_REQUEST";
export const CHAPTER_UPLOAD_SUCCEED = "CHAPTER_UPLOAD_SUCCEED";
export const CHAPTER_UPLOAD_FAILED = "CHAPTER_UPLOAD_FAILED";

export const EDIT_CHAPTER_REQUEST = "EDIT_CHAPTER_REQUEST";
export const EDIT_CHAPTER_SUCCEED = "EDIT_CHAPTER_SUCCEED";
export const EDIT_CHAPTER_FAILED = "EDIT_CHAPTER_FAILED";

export const DELETE_CHAPTER_REQUEST = "DELETE_CHAPTER_REQUEST";
export const DELETE_CHAPTER_SUCCEED = "DELETE_CHAPTER_SUCCEED";
export const DELETE_CHAPTER_FAILED = "DELETE_CHAPTER_FAILED";

export const GET_CHAPTER_REQUEST = "GET_CHAPTER_REQUEST";
export const GET_CHAPTER_SUCCESS = "GET_CHAPTER_SUCCESS";
export const GET_CHAPTER_FAILED = "GET_CHAPTER_FAILED";

export const GET_CHAPTERS_BY_BOOK_REQUEST = "GET_CHAPTERS_BY_BOOK_REQUEST";
export const GET_CHAPTERS_BY_BOOK_SUCCESS = "GET_CHAPTERS_BY_BOOK_SUCCESS";
export const GET_CHAPTERS_BY_BOOK_FAILED = "GET_CHAPTERS_BY_BOOK_FAILED";

export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_REQUEST = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_REQUEST";
export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_SUCCESS = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_SUCCESS";
export const GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_FAILED = "GET_CHAPTERS_BY_BOOK_AND_LANGUAGE_FAILED";

export const GET_ALL_CHAPTER_REQUEST = "GET_ALL_CHAPTERS_REQUEST";
export const GET_ALL_CHAPTER_SUCCESS = "GET_ALL_CHAPTERS_SUCCESS";
export const GET_ALL_CHAPTER_FAILED = "GET_ALL_CHAPTERS_FAILED";

export const GET_ADAPTED_CHAPTERS_REQUEST = "GET_ADAPTED_CHAPTERS_REQUEST";
export const GET_ADAPTED_CHAPTERS_SUCCESS = "GET_ADAPTED_CHAPTERS_SUCCESS";
export const GET_ADAPTED_CHAPTERS_FAILED = "GET_ADAPTED_CHAPTERS_FAILED";

export const GET_PROGRESS_REQUEST = "GET_PROGRESSS_REQUEST";
export const GET_PROGRESS_SUCCESS = "GET_PROGRESSS_SUCCESS";
export const GET_PROGRESS_FAILED = "GET_PROGRESSS_FAILED";

export const SAVE_PROGRESS_REQUEST = "SAVE_PROGRESS_REQUEST";
export const SAVE_PROGRESS_SUCCESS = "SAVE_PROGRESS_SUCCESS";
export const SAVE_PROGRESS_FAILED = "SAVE_PROGRESS_FAILED";
