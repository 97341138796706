export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_FAILED = "ADD_IMAGE_FAILED";

export const EDIT_IMAGE_REQUEST = "EDIT_IMAGE_REQUEST";
export const EDIT_IMAGE_SUCCESS = "EDIT_IMAGE_SUCCESS";
export const EDIT_IMAGE_FAILED = "EDIT_IMAGE_FAILED";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILED = "DELETE_IMAGE_FAILED";

export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILED = "GET_ALL_IMAGES_FAILED";

export const GET_ALL_IMAGE_TAGS_REQUEST = "GET_ALL_IMAGE_TAGS_REQUEST";
export const GET_ALL_IMAGE_TAGS_SUCCESS = "GET_ALL_IMAGE_TAGS_SUCCESS";
export const GET_ALL_IMAGE_TAGS_FAILED = "GET_ALL_IMAGE_TAGS_FAILED";

export const GET_TAGS_BY_IMAGE_REQUEST = "GET_TAGS_BY_IMAGE_REQUEST";
export const GET_TAGS_BY_IMAGE_SUCCESS = "GET_TAGS_BY_IMAGE_SUCCESS";
export const GET_TAGS_BY_IMAGE_FAILED = "GET_TAGS_BY_IMAGE_FAILED";

export const ADD_IMAGE_TAG_REQUEST = "ADD_IMAGE_TAG_REQUEST";
export const ADD_IMAGE_TAG_SUCCESS = "ADD_IMAGE_TAG_SUCCESS";
export const ADD_IMAGE_TAG_FAILED = "ADD_IMAGE_TAG_FAILED";

export const EDIT_IMAGE_TAG_REQUEST = "EDIT_IMAGE_TAG_REQUEST";
export const EDIT_IMAGE_TAG_SUCCESS = "EDIT_IMAGE_TAG_SUCCESS";
export const EDIT_IMAGE_TAG_FAILED = "EDIT_IMAGE_TAG_FAILED";

export const DELETE_IMAGE_TAG_REQUEST = "DELETE_IMAGE_TAG_REQUEST";
export const DELETE_IMAGE_TAG_SUCCESS = "DELETE_IMAGE_TAG_SUCCESS";
export const DELETE_IMAGE_TAG_FAILED = "DELETE_IMAGE_TAG_FAILED";

export const ADD_TO_FAV_REQUEST = "ADD_TO_FAV_REQUEST";
export const ADD_TO_FAV_SUCCESS = "ADD_TO_FAV_SUCCESS";
export const ADD_TO_FAV_FAILED = "ADD_TO_FAV_FAILED";
